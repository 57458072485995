import { _ as _async_to_generator } from "@swc/helpers/_/_async_to_generator";
import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import * as getAllWarehouseActions from "../../slices/master/getAllWarehouse.slice";
import { http } from "utils/http";
import { endPoint } from "constants/server/api";
export var getAllWarehouse = function() {
    var queryParams = (arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}).queryParams;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var url, queryString, config, result, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        dispatch(getAllWarehouseActions.fetchData());
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            3,
                            ,
                            4
                        ]);
                        url = endPoint.WAREHOUSE;
                        if (queryParams) {
                            queryString = new URLSearchParams(queryParams).toString();
                            url = "".concat(url, "?").concat(queryString);
                        }
                        config = {
                            method: "get",
                            url: url
                        };
                        return [
                            4,
                            http(config)
                        ];
                    case 2:
                        result = _state.sent();
                        dispatch(getAllWarehouseActions.fetchDataSuccess(result.data));
                        return [
                            3,
                            4
                        ];
                    case 3:
                        error = _state.sent();
                        dispatch(getAllWarehouseActions.fetchDataFailure());
                        return [
                            3,
                            4
                        ];
                    case 4:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
