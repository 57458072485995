export var testData = {
    id: 35,
    dim_mst_financial_status_id: 2,
    dim_mst_new_status_id: 6,
    dim_mst_payment_type_id: 1,
    dim_mst_price_category_id: 1,
    dim_warehouse_id: 7,
    dim_new_alldernow_emp_id: 96,
    dim_supplier_information_id: 3,
    dim_new_purchase_order_id: 45,
    dim_new_po_split_id: 117,
    doc_number: "GR-241000021",
    po_split_refer: "PO-241000009",
    full_supplier_name: "บริษัท ทดสอบ จำกัด (00000)-สำนักงานใหญ่",
    full_supplier_address_name: "123 Business St., คลองจั่น, บางกะปิ, กรุงเทพมหานคร, 10240",
    supplier_phone_number: "0987654321",
    supplier_nickname: "ทดสอบชื่อย่อ",
    refer: "REF98765",
    refer_date: null,
    dim_mst_discount_type_id: 1,
    discount_value: 10,
    total_price_before_discount: 8988,
    discount_price: 898.8,
    total_price_after_discount: 8089.2,
    total_price_product_no_vat: 0,
    total_price_product_zero_vat: 0,
    total_price_product_seven_vat: 7560,
    total_seven_vat_amount: 529.2,
    total_price_vat: 529.2,
    total_price_before_vat: 7560,
    grand_total_price: 8089.2,
    sum_item_po_split_quantity: 9,
    total_received_quantity: 7,
    total_receive_quantity: 7,
    total_remaining_quantity: 2,
    remark: null,
    doc_receive_date: "2024-10-10T00:00:00.000Z",
    doc_receive_time: "11:11",
    delivery_date: "2024-10-10T00:00:00.000Z",
    delivery_time: "11:11",
    doc_due_date: "2024-11-24T00:00:00.000Z",
    not_approve_reason: null,
    is_update_stock: true,
    canceled_at: null,
    canceled_by_emp_id: null,
    created_at: "2024-10-10T14:17:07.000Z",
    updated_at: "2024-12-12T12:23:12.000Z",
    dimSupplierInformation: {
        id: 3,
        dim_mst_business_type_id: 2,
        dim_mst_business_category_id: 1,
        dim_group_of_supplier_id: 4,
        dim_mst_prefix_id: 1,
        code: null,
        credit_days: 0,
        img_path: "media/dim-supplier-information/1712475329031-61755608.jpg",
        tax_identification_number: "123456789",
        first_name: "John",
        last_name: "Doe",
        initials: "ทดสอบชื่อย่อ",
        dim_mst_company_prefix_id: 2,
        company_name: "ทดสอบ",
        company_branch_code: "00000",
        company_branch_name: "สำนักงานใหญ่",
        company_address: "123 Business St.",
        company_sub_district: "คลองจั่น",
        company_district: "บางกะปิ",
        company_province: "กรุงเทพมหานคร",
        company_postal_code: "10240",
        company_phone_number: "0987654321",
        company_mobile_phone_number: "0994567899",
        company_email: "contact@business.com",
        company_website: "http://www.business.com",
        doc_delivery_address: "456 Delivery Ave.",
        doc_delivery_sub_district: "บางแค",
        doc_delivery_district: "บางแค",
        doc_delivery_province: "กรุงเทพมหานคร",
        doc_delivery_postal_code: "10160",
        doc_delivery_phone_number: "1234567890",
        doc_delivery_mobile_phone_number: "0987654321",
        doc_delivery_email: "delivery@business.com",
        main_contact_first_name: "Jane",
        main_contact_last_name: "Smith",
        main_contact_nick_name: "Jay",
        main_contact_email: "jane.smith@business.com",
        main_contact_phone_number: "0987654321",
        main_contact_mobile_phone_number: "0889995555",
        main_contact_position: "Manager",
        alternate_contact_first_name: "Mike",
        alternate_contact_last_name: "Johnson",
        alternate_contact_nick_name: "Mikey",
        alternate_contact_email: "mike.johnson@business.com",
        alternate_contact_phone_number: "1234567890",
        alternate_contact_mobile_phone_number: "0778886666",
        alternate_contact_position: "Assistant Manager",
        dim_mst_price_category_id: null,
        dim_mst_payment_term_id: 1,
        dim_mst_payment_method_id: 1,
        dim_mst_payment_type_id: null,
        is_credit_limit: true,
        received_credit_limit: 12400,
        line_token: "alksjdfljk23o4",
        is_active: true,
        created_by: 1,
        created_at: "2024-04-05T15:25:22.000Z",
        updated_at: "2024-04-08T14:18:07.000Z",
        dimGroupOfSupplier: {
            id: 4,
            code: "GRP001",
            name: "Supplier Group A Edit",
            desc: "Description of Supplier Group A.",
            is_active: true,
            created_at: "2024-04-08T10:57:23.000Z",
            updated_at: "2024-04-08T11:01:12.000Z",
            deleted_at: null
        },
        dimMstCompanyPrefix: {
            id: 2,
            name: "บริษัท",
            prefix: "บริษัท",
            suffix: "จำกัด",
            is_active: true,
            created_at: "2024-04-05T02:50:08.000Z",
            updated_at: "2024-08-16T11:48:33.000Z"
        }
    },
    dimMstNewStatus: {
        id: 6,
        name: "เสร็จสิ้น",
        is_active: true,
        created_at: "2024-05-11T00:51:19.000Z",
        updated_at: "2024-05-11T00:51:19.000Z"
    },
    dimWarehouse: {
        id: 7,
        warehouse_code: "WH-PYO005",
        name: "คลังสาขา รร.พะเยาเกทเวย์",
        type: "คลังสินค้าสาขา",
        province: "พะเยา",
        amphoe: "เมืองพะเยา",
        address: null,
        url_google_map: null,
        branch_id: 6,
        created_at: "2022-12-05T04:13:12.000Z",
        updated_at: "2022-12-05T04:13:12.000Z"
    },
    dimNewGrItem: [
        {
            id: 92,
            dim_new_gr_id: 35,
            dim_product_price_id: 21507,
            dim_new_po_split_item_id: 289,
            dim_warehouse_id: 7,
            unit_id: 2,
            ratio_unit: 12,
            container: "1x12",
            po_split_quantity: 2,
            avg_discount_before_vat: 1080,
            unit_price: 1200,
            dim_mst_discount_type_id: 1,
            discount_value: 0,
            discount_price: 0,
            total_price_before_vat: 2400,
            total_price_include_vat: 2568,
            received_quantity: 2,
            receive_quantity: 2,
            remaining_quantity: 0,
            dim_mst_vat_id: 3,
            remark: null,
            created_at: "2024-10-10T14:17:07.000Z",
            updated_at: "2024-12-12T12:23:12.000Z",
            dimMasterUnit: {
                id: 2,
                name: "ลัง",
                desc: "ลัง",
                is_active: true,
                created_at: "2021-05-05T13:38:04.000Z",
                updated_at: "2021-05-05T13:38:04.000Z"
            },
            dimMstVat: {
                id: 3,
                name: "7 %",
                value: 7,
                is_active: true,
                created_at: "2024-05-11T00:51:19.000Z",
                updated_at: "2024-05-11T00:51:19.000Z"
            },
            dimWarehouse: {
                id: 7,
                warehouse_code: "WH-PYO005",
                name: "คลังสาขา รร.พะเยาเกทเวย์",
                type: "คลังสินค้าสาขา",
                province: "พะเยา",
                amphoe: "เมืองพะเยา",
                address: null,
                url_google_map: null,
                branch_id: 6,
                created_at: "2022-12-05T04:13:12.000Z",
                updated_at: "2022-12-05T04:13:12.000Z"
            },
            dimProductPrice: {
                id: 21507,
                product_id: 7223,
                barcode: "885929611X12",
                code_sales: null,
                price_1: 1560,
                price_2: null,
                price_3: null,
                price_4: null,
                price_5: null,
                unit_id: 2,
                ratio_unit: 12,
                container: "1x12",
                is_new: false,
                is_new_sort: 0,
                is_hot: false,
                is_hot_sort: 0,
                is_web: false,
                width: 0,
                length: 0,
                height: 0,
                weight: 0,
                weight_name: null,
                is_web_flash_sales: false,
                price_flash_sales: 0,
                number_per_person_flash_sales: 0,
                number_per_product: 0,
                number_flash_sales_use: 0,
                is_come_on: false,
                is_promotion_web: false,
                promotion_web_discount: 0,
                is_delete: false,
                created_at: "2024-05-14T08:07:17.000Z",
                updated_at: "2024-05-14T08:10:36.000Z",
                deleted_at: null,
                dimProduct: {
                    id: 7223,
                    sku: "12233",
                    barcode: "885929611",
                    name_th: "ยูยี ผงโกโก้ 500g.+ซันเดย์ช็อกโกแลตท้อปปิ้ง 600g.",
                    name_en: "ยูยี ผงโกโก้ 500g.+ซันเดย์ช็อกโกแลตท้อปปิ้ง 600g.",
                    supplier_names: null,
                    supplier_code: "ABC123",
                    formula_id: null,
                    family_id: null,
                    category_id: 1,
                    sub_category_id: 1,
                    sub_category_2_id: 2,
                    divition_id: null,
                    brand_id: 17,
                    desc: null,
                    sub_unit_id: 21,
                    unit_id: 2,
                    ratio_sub_unit: 12,
                    sub_unit_cost: 100,
                    unit_cost: 1200,
                    vat: "VAT 7%",
                    minimum_sales: null,
                    packing_size_name_th: null,
                    packing_size_th: null,
                    packing_size_name_en: null,
                    packing_size_en: null,
                    registration_number: null,
                    tisi: null,
                    weight: null,
                    weight_name: null,
                    wide: null,
                    long: null,
                    height: null,
                    dimension_unit: null,
                    storage_id: null,
                    initial_product_quantity: null,
                    minimum_alert: null,
                    over_stock: null,
                    is_able_sell_negative: null,
                    remark: null,
                    user_id: 34,
                    is_web: false,
                    is_closed: false,
                    is_delete: false,
                    created_at: "2024-05-14T08:07:17.000Z",
                    updated_at: "2024-10-05T21:24:22.000Z",
                    deleted_at: null
                }
            },
            dimGrLot: [
                {
                    id: 40,
                    dim_new_gr_item_id: 92,
                    lot_number: "GR-241000021",
                    receive_quantity: 2,
                    date_expired: "2024-10-23T17:00:00.000Z",
                    created_at: "2024-10-10T14:17:07.000Z",
                    updated_at: "2024-10-10T14:17:07.000Z"
                }
            ],
            dimNewPoSplitItem: {
                total_price_before_vat: 2400,
                total_price_include_vat: 2568
            }
        },
        {
            id: 93,
            dim_new_gr_id: 35,
            dim_product_price_id: 21507,
            dim_new_po_split_item_id: 290,
            dim_warehouse_id: 7,
            unit_id: 2,
            ratio_unit: 12,
            container: "1x12",
            po_split_quantity: 3,
            avg_discount_before_vat: 1080,
            unit_price: 1200,
            dim_mst_discount_type_id: 1,
            discount_value: 0,
            discount_price: 0,
            total_price_before_vat: 2400,
            total_price_include_vat: 2568,
            received_quantity: 2,
            receive_quantity: 2,
            remaining_quantity: 1,
            dim_mst_vat_id: 3,
            remark: null,
            created_at: "2024-10-10T14:17:07.000Z",
            updated_at: "2024-12-12T12:23:12.000Z",
            dimMasterUnit: {
                id: 2,
                name: "ลัง",
                desc: "ลัง",
                is_active: true,
                created_at: "2021-05-05T13:38:04.000Z",
                updated_at: "2021-05-05T13:38:04.000Z"
            },
            dimMstVat: {
                id: 3,
                name: "7 %",
                value: 7,
                is_active: true,
                created_at: "2024-05-11T00:51:19.000Z",
                updated_at: "2024-05-11T00:51:19.000Z"
            },
            dimWarehouse: {
                id: 7,
                warehouse_code: "WH-PYO005",
                name: "คลังสาขา รร.พะเยาเกทเวย์",
                type: "คลังสินค้าสาขา",
                province: "พะเยา",
                amphoe: "เมืองพะเยา",
                address: null,
                url_google_map: null,
                branch_id: 6,
                created_at: "2022-12-05T04:13:12.000Z",
                updated_at: "2022-12-05T04:13:12.000Z"
            },
            dimProductPrice: {
                id: 21507,
                product_id: 7223,
                barcode: "885929611X12",
                code_sales: null,
                price_1: 1560,
                price_2: null,
                price_3: null,
                price_4: null,
                price_5: null,
                unit_id: 2,
                ratio_unit: 12,
                container: "1x12",
                is_new: false,
                is_new_sort: 0,
                is_hot: false,
                is_hot_sort: 0,
                is_web: false,
                width: 0,
                length: 0,
                height: 0,
                weight: 0,
                weight_name: null,
                is_web_flash_sales: false,
                price_flash_sales: 0,
                number_per_person_flash_sales: 0,
                number_per_product: 0,
                number_flash_sales_use: 0,
                is_come_on: false,
                is_promotion_web: false,
                promotion_web_discount: 0,
                is_delete: false,
                created_at: "2024-05-14T08:07:17.000Z",
                updated_at: "2024-05-14T08:10:36.000Z",
                deleted_at: null,
                dimProduct: {
                    id: 7223,
                    sku: "12233",
                    barcode: "885929611",
                    name_th: "ยูยี ผงโกโก้ 500g.+ซันเดย์ช็อกโกแลตท้อปปิ้ง 600g.",
                    name_en: "ยูยี ผงโกโก้ 500g.+ซันเดย์ช็อกโกแลตท้อปปิ้ง 600g.",
                    supplier_names: null,
                    supplier_code: "ABC123",
                    formula_id: null,
                    family_id: null,
                    category_id: 1,
                    sub_category_id: 1,
                    sub_category_2_id: 2,
                    divition_id: null,
                    brand_id: 17,
                    desc: null,
                    sub_unit_id: 21,
                    unit_id: 2,
                    ratio_sub_unit: 12,
                    sub_unit_cost: 100,
                    unit_cost: 1200,
                    vat: "VAT 7%",
                    minimum_sales: null,
                    packing_size_name_th: null,
                    packing_size_th: null,
                    packing_size_name_en: null,
                    packing_size_en: null,
                    registration_number: null,
                    tisi: null,
                    weight: null,
                    weight_name: null,
                    wide: null,
                    long: null,
                    height: null,
                    dimension_unit: null,
                    storage_id: null,
                    initial_product_quantity: null,
                    minimum_alert: null,
                    over_stock: null,
                    is_able_sell_negative: null,
                    remark: null,
                    user_id: 34,
                    is_web: false,
                    is_closed: false,
                    is_delete: false,
                    created_at: "2024-05-14T08:07:17.000Z",
                    updated_at: "2024-10-05T21:24:22.000Z",
                    deleted_at: null
                }
            },
            dimGrLot: [
                {
                    id: 41,
                    dim_new_gr_item_id: 93,
                    lot_number: "1",
                    receive_quantity: 1,
                    date_expired: "2024-11-01T17:00:00.000Z",
                    created_at: "2024-10-10T14:17:07.000Z",
                    updated_at: "2024-10-10T14:17:07.000Z"
                },
                {
                    id: 42,
                    dim_new_gr_item_id: 93,
                    lot_number: "2",
                    receive_quantity: 1,
                    date_expired: "2024-10-29T17:00:00.000Z",
                    created_at: "2024-10-10T14:17:07.000Z",
                    updated_at: "2024-10-10T14:17:07.000Z"
                }
            ],
            dimNewPoSplitItem: {
                total_price_before_vat: 3600,
                total_price_include_vat: 3852
            }
        },
        {
            id: 94,
            dim_new_gr_id: 35,
            dim_product_price_id: 21507,
            dim_new_po_split_item_id: 291,
            dim_warehouse_id: 7,
            unit_id: 2,
            ratio_unit: 12,
            container: "1x12",
            po_split_quantity: 4,
            avg_discount_before_vat: 1080,
            unit_price: 1200,
            dim_mst_discount_type_id: 1,
            discount_value: 0,
            discount_price: 0,
            total_price_before_vat: 3600,
            total_price_include_vat: 3852,
            received_quantity: 3,
            receive_quantity: 3,
            remaining_quantity: 1,
            dim_mst_vat_id: 3,
            remark: null,
            created_at: "2024-10-10T14:17:07.000Z",
            updated_at: "2024-12-12T12:23:12.000Z",
            dimMasterUnit: {
                id: 2,
                name: "ลัง",
                desc: "ลัง",
                is_active: true,
                created_at: "2021-05-05T13:38:04.000Z",
                updated_at: "2021-05-05T13:38:04.000Z"
            },
            dimMstVat: {
                id: 3,
                name: "7 %",
                value: 7,
                is_active: true,
                created_at: "2024-05-11T00:51:19.000Z",
                updated_at: "2024-05-11T00:51:19.000Z"
            },
            dimWarehouse: {
                id: 7,
                warehouse_code: "WH-PYO005",
                name: "คลังสาขา รร.พะเยาเกทเวย์",
                type: "คลังสินค้าสาขา",
                province: "พะเยา",
                amphoe: "เมืองพะเยา",
                address: null,
                url_google_map: null,
                branch_id: 6,
                created_at: "2022-12-05T04:13:12.000Z",
                updated_at: "2022-12-05T04:13:12.000Z"
            },
            dimProductPrice: {
                id: 21507,
                product_id: 7223,
                barcode: "885929611X12",
                code_sales: null,
                price_1: 1560,
                price_2: null,
                price_3: null,
                price_4: null,
                price_5: null,
                unit_id: 2,
                ratio_unit: 12,
                container: "1x12",
                is_new: false,
                is_new_sort: 0,
                is_hot: false,
                is_hot_sort: 0,
                is_web: false,
                width: 0,
                length: 0,
                height: 0,
                weight: 0,
                weight_name: null,
                is_web_flash_sales: false,
                price_flash_sales: 0,
                number_per_person_flash_sales: 0,
                number_per_product: 0,
                number_flash_sales_use: 0,
                is_come_on: false,
                is_promotion_web: false,
                promotion_web_discount: 0,
                is_delete: false,
                created_at: "2024-05-14T08:07:17.000Z",
                updated_at: "2024-05-14T08:10:36.000Z",
                deleted_at: null,
                dimProduct: {
                    id: 7223,
                    sku: "12233",
                    barcode: "885929611",
                    name_th: "ยูยี ผงโกโก้ 500g.+ซันเดย์ช็อกโกแลตท้อปปิ้ง 600g.",
                    name_en: "ยูยี ผงโกโก้ 500g.+ซันเดย์ช็อกโกแลตท้อปปิ้ง 600g.",
                    supplier_names: null,
                    supplier_code: "ABC123",
                    formula_id: null,
                    family_id: null,
                    category_id: 1,
                    sub_category_id: 1,
                    sub_category_2_id: 2,
                    divition_id: null,
                    brand_id: 17,
                    desc: null,
                    sub_unit_id: 21,
                    unit_id: 2,
                    ratio_sub_unit: 12,
                    sub_unit_cost: 100,
                    unit_cost: 1200,
                    vat: "VAT 7%",
                    minimum_sales: null,
                    packing_size_name_th: null,
                    packing_size_th: null,
                    packing_size_name_en: null,
                    packing_size_en: null,
                    registration_number: null,
                    tisi: null,
                    weight: null,
                    weight_name: null,
                    wide: null,
                    long: null,
                    height: null,
                    dimension_unit: null,
                    storage_id: null,
                    initial_product_quantity: null,
                    minimum_alert: null,
                    over_stock: null,
                    is_able_sell_negative: null,
                    remark: null,
                    user_id: 34,
                    is_web: false,
                    is_closed: false,
                    is_delete: false,
                    created_at: "2024-05-14T08:07:17.000Z",
                    updated_at: "2024-10-05T21:24:22.000Z",
                    deleted_at: null
                }
            },
            dimGrLot: [
                {
                    id: 43,
                    dim_new_gr_item_id: 94,
                    lot_number: "1",
                    receive_quantity: 2,
                    date_expired: "2024-10-27T17:00:00.000Z",
                    created_at: "2024-10-10T14:17:07.000Z",
                    updated_at: "2024-10-10T14:17:07.000Z"
                },
                {
                    id: 44,
                    dim_new_gr_item_id: 94,
                    lot_number: "2",
                    receive_quantity: 1,
                    date_expired: null,
                    created_at: "2024-10-10T14:17:07.000Z",
                    updated_at: "2024-10-10T14:17:07.000Z"
                }
            ],
            dimNewPoSplitItem: {
                total_price_before_vat: 4800,
                total_price_include_vat: 5136
            }
        }
    ],
    dimTagGr: [],
    dimDocFileGr: [],
    dimNewPurchaseOrder: {
        id: 45
    },
    createdNewGrEmp: {
        id: 96,
        dim_mst_prefix_id: 1,
        dim_mst_sex_id: null,
        dim_mst_marital_status_id: null,
        dim_mst_military_status_id: null,
        dim_branch_id: 3,
        emp_code: "SPADMIN01",
        img_path: "media/dim-new-alldernow-emp/1712585961547-118698994.jpg",
        first_name_th: "ซุปเปอร์แอดมิน",
        last_name_th: "ซุปเปอร์แอดมิน",
        first_name_en: "test",
        last_name_en: "eng",
        id_card: null,
        nick_name: null,
        birth_date: null,
        age: null,
        ethnicity: null,
        nationality: null,
        dim_mst_relationship_id: null,
        address: "123 ถนนทดสอบ",
        sub_district: null,
        district: null,
        province: null,
        postal_code: null,
        email: "superadmin@admin.com",
        phone_number: null,
        line: null,
        facebook: null,
        instagram: null,
        emergency_contact_person: null,
        emergency_phone_number: null,
        dim_mst_bank_id: null,
        dim_mst_bank_account_type_id: null,
        is_bank_account: false,
        bank_account_name: null,
        bank_account_number: null,
        bank_account_branch_name: null,
        bank_account_branch_number: null,
        dim_mst_employee_type_id: null,
        dim_mst_department_id: null,
        dim_mst_position_id: null,
        start_date: "2024-04-10T00:00:00.000Z",
        end_date: null,
        is_register_social_security: false,
        cancel_by_user_id: null,
        deleted_at: null,
        created_at: "2024-04-02T01:37:42.000Z",
        updated_at: "2024-04-08T14:19:22.000Z"
    },
    canceledByEmp: null,
    dimMstFinancialStatus: {
        id: 2,
        name: "แบบร่าง",
        is_active: true,
        created_at: "2024-11-15T10:38:23.000Z",
        updated_at: "2024-11-15T10:38:23.000Z"
    }
};
