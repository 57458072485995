import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _sliced_to_array } from "@swc/helpers/_/_sliced_to_array";
import { nanoid } from "@reduxjs/toolkit";
export function mappingPoSplit(param) {
    var poSplitData = param.poSplitData, action = param.action, setPdata = param.setPdata, setProductRow = param.setProductRow, setFormdisabled = param.setFormdisabled, columnsort = param.columnsort, setColumnsort = param.setColumnsort;
    var dataWithReplacements = Object.entries(poSplitData).reduce(function(acc, param) {
        var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
        acc[key] = value === null ? "" : value;
        return acc;
    }, {});
    if (dataWithReplacements.dimNewPurchaseOrder.dimSupplierInformation) {
        var dimSupplierInformation = dataWithReplacements.dimNewPurchaseOrder.dimSupplierInformation;
        var _dataWithReplacements_dimNewPurchaseOrder = dataWithReplacements.dimNewPurchaseOrder, full_supplier_name = _dataWithReplacements_dimNewPurchaseOrder.full_supplier_name, full_supplier_address_name = _dataWithReplacements_dimNewPurchaseOrder.full_supplier_address_name;
        setPdata(function(prev) {
            return {
                id: dimSupplierInformation.id,
                dimGroupOfSupplierCode: dimSupplierInformation.dimGroupOfSupplier ? dimSupplierInformation.dimGroupOfSupplier.code : "-",
                full_supplier_name: full_supplier_name,
                initials: dimSupplierInformation.initials,
                company_branch_code: dimSupplierInformation.company_branch_code,
                company_branch_name: dimSupplierInformation.company_branch_name,
                full_supplier_address_name: full_supplier_address_name,
                company_phone_number: dimSupplierInformation.company_phone_number
            };
        });
        dataWithReplacements.full_supplier_name = full_supplier_name;
        dataWithReplacements.full_supplier_address_name = full_supplier_address_name;
        dataWithReplacements.supplier_phone_number = dimSupplierInformation.company_phone_number;
        var _dimSupplierInformation_initials;
        dataWithReplacements.supplier_nickname = (_dimSupplierInformation_initials = dimSupplierInformation.initials) !== null && _dimSupplierInformation_initials !== void 0 ? _dimSupplierInformation_initials : "-";
    }
    // if (
    //   dataWithReplacements.dimDocFilePr &&
    //   dataWithReplacements.dimDocFilePr.length > 0
    // ) {
    //   const attchments = dataWithReplacements.dimDocFilePr.map((v, i) => {
    //     const newId = nanoid();
    //     const type = v.original_file_name.split(".");
    //     const mappingData = {
    //       id: newId,
    //       docId: v.id,
    //       docFile: v.original_file_name,
    //       docFileType: type[type.length - 1],
    //       docFileData: "",
    //       docUrl: getFullUrlPath(v.file_path),
    //     };
    //     return mappingData;
    //   });
    //   setAttachments(attchments);
    // }
    // if (
    //   dataWithReplacements.dimTagPr &&
    //   dataWithReplacements.dimTagPr.length > 0
    // ) {
    //   const mappingData = dataWithReplacements.dimTagPr.map((v) => v.name);
    //   setTagSelected(mappingData);
    // }
    if (dataWithReplacements.dimNewPoSplitItem && dataWithReplacements.dimNewPoSplitItem.length > 0) {
        var productItems = dataWithReplacements.dimNewPoSplitItem.map(function(v) {
            var _dimProductPrice_dimProduct, _dimProductPrice_dimProduct1, _dimProductPrice_dimProduct2;
            var id = v.id, dim_product_price_id = v.dim_product_price_id, quantity = v.quantity, unit_price = v.unit_price, unit_id = v.unit_id, ratio_unit = v.ratio_unit, container = v.container, dim_mst_discount_type_id = v.dim_mst_discount_type_id, remark = v.remark, discount_value = v.discount_value, discount_price = v.discount_price, dimWarehouse = v.dimWarehouse, dimProductPrice = v.dimProductPrice, dimMasterUnit = v.dimMasterUnit, dimMstVat = v.dimMstVat, total_received_quantity = v.total_received_quantity, total_remaining_quantity = v.total_remaining_quantity;
            // console.log(discount_price);
            var mappingData = {
                rowID: nanoid(),
                productID: dim_product_price_id,
                dim_new_po_split_item_id: id,
                productCode: (_dimProductPrice_dimProduct = dimProductPrice.dimProduct) === null || _dimProductPrice_dimProduct === void 0 ? void 0 : _dimProductPrice_dimProduct.sku,
                productName: (_dimProductPrice_dimProduct1 = dimProductPrice.dimProduct) === null || _dimProductPrice_dimProduct1 === void 0 ? void 0 : _dimProductPrice_dimProduct1.name_th,
                productBarcode: dimProductPrice.barcode,
                productUnitID: unit_id,
                productRatioUnit: ratio_unit,
                productContainer: container,
                supplierCode: (_dimProductPrice_dimProduct2 = dimProductPrice.dimProduct) === null || _dimProductPrice_dimProduct2 === void 0 ? void 0 : _dimProductPrice_dimProduct2.supplier_code,
                warehouseID: dimWarehouse.id,
                warehouseName: dimWarehouse.name,
                productUnit: dimMasterUnit ? dimMasterUnit.name : "-",
                productQty: quantity,
                productOrdered: "",
                productOrderQuantity: quantity,
                productReceivedQuantity: total_received_quantity,
                productReceiveQuantity: 0,
                receivelist: null,
                ums: "",
                productStock: "",
                productPrice: unit_price,
                discountType: dim_mst_discount_type_id,
                discount: discount_price,
                discountPercent: discount_value,
                tax: "",
                price_before_tax: 0,
                dim_mst_vat_id: dimMstVat.id,
                vat: dimMstVat.value,
                note: remark,
                price_after_tax: 0,
                not_approve_reason: "",
                is_checked: false,
                edit: false
            };
            return mappingData;
        });
        dataWithReplacements.products = productItems;
        setProductRow(productItems);
    }
    if (action !== "create") {
        setFormdisabled(true);
    }
    dataWithReplacements.dim_mst_new_status_id = "";
    dataWithReplacements.dim_new_item_pr_sorts = [];
    if (dataWithReplacements.dimNewItemPrSort && dataWithReplacements.dimNewItemPrSort.length > 0) {
        var mappingCols = dataWithReplacements.dimNewItemPrSort.map(function(v) {
            return {
                id: v.dimMstNewItemPrCol.id,
                sortKey: v.dimMstNewItemPrCol.name
            };
        });
        while(mappingCols.length <= 3){
            mappingCols.push({
                id: "",
                sortKey: ""
            });
        }
        setColumnsort(_object_spread_props(_object_spread({}, columnsort), {
            columnsort: mappingCols
        }));
    }
    if (action === "create") {
        var _dataWithReplacements_dimWarehouse;
        var dimNewPurchaseOrder = dataWithReplacements.dimNewPurchaseOrder;
        dataWithReplacements.dim_new_po_split_id = dataWithReplacements.id;
        dataWithReplacements.po_split_refer = dataWithReplacements.doc_number;
        dataWithReplacements.doc_number = null;
        dataWithReplacements.dim_warehouse_name = (_dataWithReplacements_dimWarehouse = dataWithReplacements.dimWarehouse) === null || _dataWithReplacements_dimWarehouse === void 0 ? void 0 : _dataWithReplacements_dimWarehouse.name;
        dataWithReplacements.dim_mst_payment_type_id = dimNewPurchaseOrder.dim_mst_payment_type_id;
        dataWithReplacements.dim_mst_price_category_id = dimNewPurchaseOrder.dim_mst_price_category_id;
        dataWithReplacements.dim_new_alldernow_emp_id = dimNewPurchaseOrder.dim_new_alldernow_emp_id;
        dataWithReplacements.dim_supplier_information_id = dimNewPurchaseOrder.dim_supplier_information_id;
        dataWithReplacements.delivery_date = dimNewPurchaseOrder.delivery_date;
        dataWithReplacements.delivery_time = dimNewPurchaseOrder.delivery_time;
        dataWithReplacements.remark = dimNewPurchaseOrder.remark;
    }
    return dataWithReplacements;
}
