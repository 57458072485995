import moment from "moment";
import { calPriceByMstCategoryPriceTax, getEmpFullNameTHByEmpData, getSupplierFullName } from "./HelperFunction";
export function mappingReportPoItemData(datas) {
    var mappingData = datas.map(function(v, i) {
        // const itemData = {
        //   productPrice: v.unit_price,
        //   discount: v.discount,
        //   vat: v.dimMstVat.value,
        //   productQty: v.quantity,
        // };
        // const productExcPrice = calPriceByMstCategoryPriceTax(
        //   itemData,
        //   v.dim_mst_price_category_id,
        //   true
        // );
        // const productIncPrice = calPriceByMstCategoryPriceTax(
        //   itemData,
        //   v.dim_mst_price_category_id,
        //   false
        // );
        // const productVat =
        //   calPriceByMstCategoryPriceTax(
        //     itemData,
        //     v.dim_mst_price_category_id,
        //     false
        //   ) -
        //   calPriceByMstCategoryPriceTax(
        //     itemData,
        //     v.dim_mst_price_category_id,
        //     true
        //   );
        var mapping = {
            ลำดับที่: i + 1,
            วันที่ออกเอกสาร: moment(v.dimNewPurchaseOrder.doc_issue_date).format("DD/MM/YYYY"),
            เลขที่ใบสั่งซื้อ: v.dimNewPurchaseOrder.doc_number,
            ผู้จัดจำหน่าย: getSupplierFullName(v.dimNewPurchaseOrder.dimSupplierInformation),
            รหัสสินค้า: v.dimProductPrice.dimProduct.sku,
            บาร์โค๊ด: v.dimProductPrice.barcode,
            ชื่อสินค้า: v.dimProductPrice.dimProduct.name_th,
            คลังจัดส่ง: v.dimWarehouse.name,
            หน่วย: "".concat(v.dimMasterUnit.name, " 1X").concat(v.ratio_unit),
            จำนวน: v.quantity,
            "ราคา/หน่วย": v.unit_price,
            ประเภทส่วนลด: v.dimMstVat ? v.dimMstVat.name : "-",
            "ส่วนลด/หน่วย": v.discount_price,
            ภาษี: v.dimMstVat.name,
            มูลค่าก่อนภาษี: v.total_price_before_vat,
            ภาษีมูลค่าเพิ่ม: v.total_price_include_vat - v.total_price_before_vat,
            มูลค่ารวมภาษี: v.total_price_include_vat,
            หมายเหตุ: v.remark,
            ผู้สั่งซื้อ: getEmpFullNameTHByEmpData(v.dimNewPurchaseOrder.createdNewPoEmp)
        };
        return mapping;
    });
    return mappingData;
}
