import moment from "moment/moment";
export function mappingReportPurchaseOrderData(datas) {
    var mappingDatas = datas.map(function(v) {
        return {
            // date : moment(v.doc_issue_date).format('DD-MM-YYYY'),
            date: v.doc_issue_date,
            supplierName: v.full_supplier_name,
            // supplierGroup : v.dimGroupOfSupplier.name,
            productPrice: v.total_price_before_discount,
            discount: v.discount_price,
            productPriceIncVat: v.grand_total_price,
            productPriceExVat: v.total_price_before_vat,
            vat: v.total_price_vat,
            product: v.sum_item_quantity,
            bill: v.total_documents,
            productReturnBill: 0,
            productReturnPrice: 0
        };
    });
    return mappingDatas;
}
