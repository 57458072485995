import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { createSlice } from "@reduxjs/toolkit";
import { endPoint } from "constants/server/api";
var initialState = {
    data: null,
    isFetching: false,
    isError: false
};
var getAllMstOfficeSectionSlice = createSlice({
    name: "getAllMstOfficeSection",
    initialState: initialState,
    reducers: {
        fetchData: function(state) {
            state.isFetching = true;
            state.data = null;
            state.isError = false;
        },
        fetchDataSuccess: function(state, action) {
            return _object_spread_props(_object_spread({}, state), {
                isFetching: false,
                data: action.payload
            });
        },
        fetchDataFailure: function(state) {
            return _object_spread_props(_object_spread({}, state), {
                isFetching: false,
                isError: true
            });
        },
        resetState: function() {
            return initialState;
        }
    }
});
var _getAllMstOfficeSectionSlice_actions = getAllMstOfficeSectionSlice.actions;
export var fetchData = _getAllMstOfficeSectionSlice_actions.fetchData, fetchDataSuccess = _getAllMstOfficeSectionSlice_actions.fetchDataSuccess, fetchDataFailure = _getAllMstOfficeSectionSlice_actions.fetchDataFailure, resetState = _getAllMstOfficeSectionSlice_actions.resetState;
export default getAllMstOfficeSectionSlice.reducer;
